import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Login from './components/Login';
import Admin from './components/Admin';
import PlayerStatistics from './components/PlayerStatistics'
import './App.css';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import Standings from './components/Standings';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import GeneralInfo from './components/GeneralInfo';


const firebaseConfig = {
  apiKey: "AIzaSyDGjBwuI4waVKRjU6tLEvU6EMxcQqOum1M",
    authDomain: "hysh-base.firebaseapp.com",
    projectId: "hysh-base",
    storageBucket: "hysh-base.appspot.com",
    messagingSenderId: "630889327250",
    appId: "1:630889327250:web:008867fd6b8033629a144b",
    measurementId: "G-1C7GB41PQ9"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

const App = () => {

  //STANDINGS

  const [teams, setTeams] = useState([]);
  const [teamsB, setTeamsB] = useState([]);

  useEffect(() => {
    // Fetch teams from Firebase Realtime Database
    const teamsRef = ref(database, 'teams');
    const teamsListener = onValue(teamsRef, (snapshot) => {
      const teamsData = snapshot.val() || [];
      setTeams(teamsData);
    });

    const teamsBRef = ref(database, 'teamsB');
    const teamsBListener = onValue(teamsBRef, (snapshot) => {
      const teamsBData = snapshot.val() || [];
      setTeamsB(teamsBData);
    });

    const playersRef = ref(database, 'players');
    const playersListener = onValue(playersRef, (snapshot) => {
      const playersData = snapshot.val() || [];
      setPlayers(playersData);
    });

    const playersBRef = ref(database, 'playersB');
    const playersBListener = onValue(playersBRef, (snapshot) => {
      const playersBData = snapshot.val() || [];
      setPlayersB(playersBData);
    });

    // Clean up the listener when the component unmounts
    return () => {
      off(teamsRef, 'value', teamsListener);
      off(playersRef, 'value', playersListener);
      off(playersBRef, 'value', playersBListener);
      off(teamsBRef, 'value', teamsBListener);
    };
  }, []);
  //LOGIN

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
      console.log('Logged in successfully');
      setIsLoggedIn(!isLoggedIn)
    } catch (error) {
      console.error('Login failed', error.message);
    }
  };

  // PLAYERS
  const [players, setPlayers] = useState([]);
  const [playersB, setPlayersB] = useState([]);

  return (
    <Router>
      <div className="container">
        <div className="tabs">
          <Link to="/">Announcements</Link>
          <Link to="/info">Info</Link>
          <Link to="/event-times">Schedule</Link>
          <Link to="/standings">Standings</Link>
          <Link to="/player-statistics">Player Stats</Link>
          <Link to="/login">{isLoggedIn ? "Log Out" : "Login"}</Link>
          {isLoggedIn && <Link to="/admin">Manage A</Link>}
          {isLoggedIn && <Link to="/admin-b">Manage B</Link>}
        </div>
        <Routes>
        <Route
          path="info"
          element={
            <div>
              <GeneralInfo 
              database={database}
              datapull={'info'}
              topText={"General Information"}
              isAuthenticated={isLoggedIn} />
            </div>  
          }
          />
          <Route
            path="/standings"
            element={(
            <div>
              <Standings teams={teams} divisionName={"A Division"}/>
              <Standings teams={teamsB} divisionName={"B Division"}/>
            </div>  
            )}
          />
          <Route
            path="/player-statistics"
            element={(
              <div>
                <PlayerStatistics players={players} divisonName={"A Division"}/>
                <PlayerStatistics players={playersB} divisonName={"B Division"}/>
              </div>
            )}
          />
          <Route
            path="/admin"
            element={
              <div className="content">
                {isLoggedIn ? (
                  <Admin
                  text={"A Division"}
                  datapullPlayers={'players'}
                  datapullTeams={'teams'}
                  teams={teams}
                  setTeams={setTeams}
                  database={database} // Pass the database instance to the Admin component
                  players={players}
                  setPlayers={setPlayers}
                />
                ) : (
                  <div>
                    <h1>Login Required</h1>
                    <Login onLogin={handleLogin} />
                  </div>
                )}
              </div>
            }
          />
          <Route
            path="/admin-b"
            element={
              <div className="content">
                {isLoggedIn ? (
                  <Admin
                  text={"B Division"}
                  datapullPlayers={'playersB'}
                  datapullTeams={'teamsB'}
                  teams={teamsB}
                  setTeams={setTeamsB}
                  database={database} // Pass the database instance to the Admin component
                  players={playersB}
                  setPlayers={setPlayersB}
                />
                ) : (
                  <div>
                    <h1>Login Required</h1>
                    <Login onLogin={handleLogin} />
                  </div>
                )}
              </div>
            }
          />
          <Route
            path="/login"
            element={
              <div className='content'>
                {!isLoggedIn?  <h2>Login</h2> : null}
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>{isLoggedIn ? "Log Out" : "Login"}</button>
              </div>
            }
          />
          <Route
          path="event-times"
          element={
            <div>
              <GeneralInfo 
              database={database}
              datapull={'schedule'}
              topText={'Schedule'}
              isAuthenticated={isLoggedIn} />
            </div>  
          }
          />
          <Route
          path="/"
          element={
            <div>
              <GeneralInfo 
              database={database}
              topText={"Announcements"}
              datapull={'announcements'}
              isAuthenticated={isLoggedIn} />
            </div>
            
          }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
