import React, { useEffect, useState } from 'react';
import { ref, onValue, off, set } from 'firebase/database';

const Admin = ({ text, teams, setTeams, database, players, setPlayers, datapullPlayers, datapullTeams }) => {
  useEffect(() => {
    // Set up a listener for players from Firebase Realtime Database
    const playersRef = ref(database, datapullPlayers);
    const playersListener = onValue(playersRef, (snapshot) => {
      const playersData = snapshot.val() || [];
      setPlayers(playersData);
    });

    // Clean up the listener when the component unmounts
    return () => {
      off(playersRef, 'value', playersListener);
    };
  }, [database, setPlayers, datapullPlayers]);

  useEffect(() => {
    // Set up a listener for teams from Firebase Realtime Database
    const teamsRef = ref(database, datapullTeams);
    const teamsListener = onValue(teamsRef, (snapshot) => {
      const teamsData = snapshot.val() || [];
      setTeams(teamsData);
    });

    // Clean up the listener when the component unmounts
    return () => {
      off(teamsRef, 'value', teamsListener);
    };
  }, [database, setTeams, datapullTeams]);

  const handleUpdateTeam = (index, updatedTeam) => {
    const updatedTeamList = [...teams];
  
    // Check if the team already exists in the list
    const existingTeamIndex = updatedTeamList.findIndex((team) => team.name === updatedTeam.name);
  
    if (existingTeamIndex !== -1) {
      // Update the existing team
      updatedTeamList[existingTeamIndex] = updatedTeam;
    } else {
      // If the team doesn't exist, update the team at the specified index
      updatedTeamList[index] = updatedTeam;
    }
  
    setTeams(updatedTeamList);
  
    // Update teams in Firebase Realtime Database
    set(ref(database, datapullTeams), updatedTeamList);
  };

  const handleDeleteTeam = (index) => {
    const updatedTeamList = [...teams];
    updatedTeamList.splice(index, 1);
    setTeams(updatedTeamList);

    // Update teams in Firebase Realtime Database
    set(ref(database, datapullTeams), updatedTeamList);
  };

  const handleAddTeam = () => {
    const newTeam = {
      name: 'New Team',
      wins: 0,
      losses: 0,
      otLosses: 0,
      gf: 0,
      ga: 0
    };

    setTeams((prevTeams) => [...prevTeams, newTeam]);

    // Update teams in Firebase Realtime Database
    set(ref(database, datapullTeams), [...teams, newTeam]);
  };

  const handleUpdatePlayer = (playerId, updatedPlayer) => {
    const updatedPlayerList = players.map((player) =>
      player.id === playerId ? { ...player, goals: updatedPlayer.goals, assists: updatedPlayer.assists } : player
    );
  
    setPlayers(updatedPlayerList);
  
    // Update the entire 'players' array in Firebase Realtime Database
    set(ref(database, datapullPlayers), updatedPlayerList);
  };

  const handleDeletePlayer = (index) => {
    const updatedPlayerList = [...players];
    const deletedPlayer = updatedPlayerList.splice(index, 1)[0];

    // Remove the player from their associated team
    const updatedTeams = teams.map((team) => {
      if (team.players && team.players.includes(deletedPlayer.name)) {
        team.players = team.players.filter((player) => player !== deletedPlayer.name);
      }
      return team;
    });

    setTeams(updatedTeams);
    setPlayers(updatedPlayerList);

    // Update teams and players in Firebase Realtime Database
    set(ref(database, datapullTeams), updatedTeams);
    set(ref(database, datapullPlayers), updatedPlayerList);
  };

  

  const groupPlayersByTeam = () => {
    const groupedPlayers = {};
    players.forEach((player) => {
      const teamName = player.team || 'Unassigned'; // Default to 'Unassigned' if no team
      if (!groupedPlayers[teamName]) {
        groupedPlayers[teamName] = [];
      }
      groupedPlayers[teamName].push(player);
    });
    return groupedPlayers;
  };

  const groupedPlayers = groupPlayersByTeam();

  // State for adding players via text box
  const [newPlayersText, setNewPlayersText] = useState({});

  // Function to handle adding players from text box to a specific team
  const handleAddPlayersFromTextBox = (teamName) => {
    const newPlayersArray = newPlayersText[teamName]?.split('\n').map((playerName) => ({
      name: playerName.trim(),
      id: Math.random(),
      goals: 0,
      assists: 0,
      team: teamName,
    }));

    if (newPlayersArray) {
      // Assign new players to the appropriate team
      const updatedPlayers = [...players, ...newPlayersArray];
      setPlayers(updatedPlayers);
      setNewPlayersText({ ...newPlayersText, [teamName]: '' });

      // Update players in Firebase Realtime Database
      set(ref(database, datapullPlayers), updatedPlayers);
    }
  };

  return (
    <div>
      <h1>{text}</h1>
      <h2>Team Management</h2>
      <button onClick={handleAddTeam}>Add New Team</button>
      <ul className="team-list">
        {teams.map((team, index) => (
          <li key={index}>
            <input style={{ width: '100px' }}
              type="text"
              value={team.name}
              onChange={(e) => handleUpdateTeam(index, { ...team, name: e.target.value })}
            />
            Wins:
            <input style={{ width: '50px' }}
              type="number"
              value={team.wins}
              onChange={(e) => handleUpdateTeam(index, { ...team, wins: parseInt(e.target.value, 10) || 0 })}
            />
            Losses:
            <input style={{ width: '50px' }}
              type="number"
              value={team.losses}
              onChange={(e) => handleUpdateTeam(index, { ...team, losses: parseInt(e.target.value, 10) || 0 })}
            />
            OT Losses:
            <input style={{ width: '50px' }}
              type="number"
              value={team.otLosses}
              onChange={(e) => handleUpdateTeam(index, { ...team, otLosses: parseInt(e.target.value, 10) || 0 })}
            />
            Goals For:
            <input style={{ width: '50px' }}
              type="number"
              value={team.gf}
              onChange={(e) => handleUpdateTeam(index, { ...team, gf: parseInt(e.target.value, 10) || 0 })}
            />
            Goals Against:
            <input style={{ width: '50px' }}
              type="number"
              value={team.ga}
              onChange={(e) => handleUpdateTeam(index, { ...team, ga: parseInt(e.target.value, 10) || 0 })}
            />
            {"\t"}
            <button onClick={() => handleDeleteTeam(index)}>Delete Team</button>
          </li>
        ))}
      </ul>
      <h2>Player Management</h2>
      {/* Add players via text box section */}
      <div>
        {teams.map((team) => (
          <div key={team.name}>
            <h3>{team.name}</h3>
            <textarea
              value={newPlayersText[team.name] || ''}
              onChange={(e) => setNewPlayersText({ ...newPlayersText, [team.name]: e.target.value })}
              rows={5}
              cols={30}
              placeholder={`Enter player names for ${team.name} (one per line)`}
            />
            {"\t"}
            <button onClick={() => handleAddPlayersFromTextBox(team.name)}>Add Players</button>
          </div>
        ))}
      </div>

      {/* Display grouped players by team */}
      <h2>Players Grouped by Team</h2>
      {Object.entries(groupedPlayers).map(([teamName, teamPlayers]) => (
        <div key={teamName}>
          <h3>{teamName}</h3>
          <ul>
            {teamPlayers.map((player, index) => (
              <li key={player.id}>
              <input style={{ width: '100px' }}
              type="text"
              value={player.name}
              onChange={(e) => handleUpdatePlayer(player.id, { ...player, name: e.target.value })}
            />
            Goals:
            <input
            style={{ width: '50px' }}
              type="number"
              value={player.goals}
              onChange={(e) => handleUpdatePlayer(player.id, { ...player, goals: parseInt(e.target.value, 10) || 0 })}
            />
            Assists:
            <input
            style={{ width: '50px' }}
              type="number"
              value={player.assists}
              onChange={(e) => handleUpdatePlayer(player.id, { ...player, assists: parseInt(e.target.value, 10) || 0 })}
            />{"\t"}
            <button onClick={() => handleDeletePlayer(index)}>Delete Player</button>
  
            </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Admin;
