import React, { useState, useEffect } from 'react';
import {  ref, onValue, set } from '@firebase/database';
import './GeneralInfo.css'; // Import your CSS file
import AnnouncementBar from './AnnouncementBar';


const GeneralInfo = ({ isAuthenticated, database, datapull, topText }) => {
    const [editableText, setEditableText] = useState(' ');

    useEffect(() => {
      // Fetch announcement text from Firebase on component mount
      const databaseRef = ref(database, datapull);
      onValue(databaseRef, (snapshot) => {
        const announcementText = snapshot.val();
        setEditableText(announcementText || ' ');
      });
  
      // Clean up the Firebase listener on component unmount
      return () => {
        // Detach the listener
      };
    }, [database, datapull]);
  
    const handleTextChange = (e) => {
      setEditableText(e.target.value);
    };
  
    const handleSubmit = () => {
      // Update announcement text in Firebase
      set(ref(database, datapull), editableText);
    };
    return (
      <div className="general-info-container">
        <h1>Hopatcong Youth Street Hockey League</h1>
        <AnnouncementBar message={topText}/>
        <br />
        {editableText.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
        {isAuthenticated ? (
          <div className="edit-mode">
            <textarea
              value={editableText}
              onChange={handleTextChange}
              placeholder="Edit me..."
              className="edit-textarea"
            />
            <button onClick={handleSubmit} className="submit-button">
                Save Changes
            </button>
          </div>
        ) : null }
      </div>
    );
  };
  
  export default GeneralInfo;