import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyDGjBwuI4waVKRjU6tLEvU6EMxcQqOum1M",
    authDomain: "hysh-base.firebaseapp.com",
    projectId: "hysh-base",
    storageBucket: "hysh-base.appspot.com",
    messagingSenderId: "630889327250",
    appId: "1:630889327250:web:008867fd6b8033629a144b",
    measurementId: "G-1C7GB41PQ9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
