import React from 'react';

const AnnouncementBar = ({ message }) => {
  return (
    <div
      style={{
        backgroundColor: '#eee', // Set the background color as per your design
        color: '#000', // Set the text color
        padding: '15px', // Set padding
        textAlign: 'center', // Center-align text
        fontSize: '18px', // Set font size
      }}
    >
      {message}
    </div>
  );
};

export default AnnouncementBar;
