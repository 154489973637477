import React from 'react';

const PlayerStatistics = ({ players, divisonName }) => {
  // Sort players based on total points in descending order
  const sortedPlayers = players.sort((a, b) => (b.goals + b.assists) - (a.goals + a.assists));

  return (
    <div>
      <h2>{divisonName} Player Leaderboard</h2>
      <table className="player-stats-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>Team</th>
            <th>Goals</th>
            <th>Assists</th>
            <th>Total Points</th>
          </tr>
        </thead>
        <tbody>
          {sortedPlayers.map((player, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{player.name}</td>
              <td>{player.team}</td>
              <td>{player.goals}</td>
              <td>{player.assists}</td>
              <td>{player.goals + player.assists}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerStatistics;
