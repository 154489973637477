import React from 'react';

const Standings = ({ divisionName, teams }) => {
  // Sort teams based on points (wins * 2 + OT losses)
  const sortedTeams = teams.sort((a, b) => {
    const pointsA = a.wins * 2 + a.otLosses;
    const pointsB = b.wins * 2 + b.otLosses;
    return pointsB - pointsA;
  });

  return (
    <div>
      <h2> {divisionName} Standings</h2>
      <table>
        <thead>
          <tr>
            <th>Team</th>
            <th>Wins</th>
            <th>Losses</th>
            <th>OT Losses</th>
            <th>Points</th>
            <th>GF</th>
            <th>GA</th>
          </tr>
        </thead>
        <tbody>
          {sortedTeams.map((team, index) => (
            <tr key={index}>
              <td>{team.name}</td>
              <td>{team.wins}</td>
              <td>{team.losses}</td>
              <td>{team.otLosses}</td>
              <td>{team.wins * 2 + team.otLosses}</td>
              <td>{team.gf}</td>
              <td>{team.ga}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Standings;
